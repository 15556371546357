                               
   
                                                                           
                                                                           
                                        
   
                                                 
                            
                                                          
   
                           
                                                     
   
           
                               
                           
                                       
                             
     
   
          
                                               
           
                                                                                                    
                                                                 
     
    
 

                                                 
                               
                                       
   
 
          

/**
 * Checks if any attribute is an interleaved buffer, as LMV assumes all or none attributes are interleaved
 * @param {BufferGeometry} geom
 * @returns {boolean}
 */
export function isInterleavedGeometry(geom) {
  let result;
                                 
  result = !!geom.vb;
           
                                    
                                                               
          
   
            
  return result;
}